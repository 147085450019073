import React from "react"
import { IntlProvider } from "react-intl"
import PropTypes from "prop-types"
import messages from "../messages"

const WrapIntl = ({
  element,
  props: {
    location: { pathname },
  },
}) => {
  const lang = pathname.includes("/ar/") ? "ar" : "en"
  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      {element}
    </IntlProvider>
  )
}

WrapIntl.propTypes = {
  element: PropTypes.element.isRequired,
  props: PropTypes.object.isRequired,
}

export default WrapIntl
