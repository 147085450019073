export default {
  en: {
    jordan: "Jordan Nassar",
    "menu.home": "+",
    "menu.land": "++++",
    "menu.jaffa": "+++++",
    "menu.works": "+++++++",
    "menu.installation-views": "=====",
    "menu.publications": "<><><>",
    "menu.press": "PRESS",
    "menu.cv": "CV",
    "menu.about": "about",
    "about.short_bio":
      "Jordan Nassar, b.1985 New York City, lives and works in New York City.",
  },
  ar: {
    jordan: "جوردن نصّار",
    "menu.home": "+",
    "menu.land": "++++",
    "menu.jaffa": "+++++",
    "menu.works": "+++++++",
    "menu.installation-views": "=====",
    "menu.publications": "<><><>",
    "menu.press": "إعلام",
    "menu.cv": "سيرة",
    "menu.about": "نبذة",
    "about.short_bio":
      "جوردان نصار، مواليد ١٩٨٥ مدينة نيويورك، يعيش ويعمل في مدينة نيويورك.",
  },
}
