// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-justified-gallery-js": () => import("./../src/templates/justified-gallery.js" /* webpackChunkName: "component---src-templates-justified-gallery-js" */),
  "component---src-templates-gallery-image-js": () => import("./../src/templates/gallery-image.js" /* webpackChunkName: "component---src-templates-gallery-image-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cv-js": () => import("./../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-press-js": () => import("./../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */)
}

